<template>
	<photo img="/img/e9/p3/direction" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P3.Cubicle',
						name: 'Cubiculos',
						left: 12.1, //'250px',
						top: 39.3, //'300px',
						width: 4.5, //'125px',
						height: 20 //'180px'
					},
                    {
						link: 'E9.P2.Celex',
						name: 'CELEX',
						left: 65.1, //'1660px',
						top: 42.4, //'400px',
						width: 22, //'160px',
						height: 29.7 //'160px'
					},
				]
			}
		}
	}
</script>
